/**
 * All app permissions
 * @property { String } value - the name of the permission
 * @property { Boolean } enabled - default enabled / disabled status of checkbox
 * @property { Boolean } checked - default checked status of checkbox
 * @property { Array } dependsOn - a list of dependencies, the permission won't be enabled until all dependencies are checked
 *
 * Structuring of the value property:
 * ACTION_OWNERSHIP_TARGET_DATA_SUBDATA
 * *SUBDATA* is optional
 * e.g. update_own_user_email
 */
export default {
	globalPermissions: [
		/**
		 * GENERAL ACCESS PERMS
		 */
		{ value: 'access_global_admin_view', enabled: true, checked: false, dependsOn: ['read_own_user_data'] },
		{ value: 'access_global_client_view', enabled: true, checked: false, dependsOn: ['read_own_user_data'] },

		/**
		 * SYSTEM PERMS
		 */		
		{ value: 'system_log_out_user', enabled: true, checked: false, dependsOn: ['update_other_user_data'] },

		/**
		 * USER DATA PERMS
		 */
		{ value: 'create_global_user', enabled: true, checked: false, dependsOn: ['update_other_user_data'] },

		{ value: 'delete_global_user', enabled: true, checked: false, dependsOn: ['update_other_user_data'] },

		{ value: 'read_own_user_data', enabled: true, checked: false },
		{ value: 'read_other_user_data', enabled: true, checked: false, dependsOn: ['read_own_user_data'] },

		{ value: 'update_own_user_data', enabled: true, checked: false, dependsOn: ['read_own_user_data'] },
		{ value: 'update_other_user_data', enabled: true, checked: false, dependsOn: ['read_other_user_data', 'update_own_user_data'] },

		{ value: 'read_own_user_avatar', enabled: true, checked: false, dependsOn: ['read_own_user_data'] },
		{ value: 'read_other_user_avatar', enabled: true, checked: false, dependsOn: ['read_other_user_data', 'read_own_user_avatar'] },
		{ value: 'update_own_user_avatar', enabled: true, checked: false, dependsOn: ['update_own_user_data', 'read_own_user_avatar'] },
		{ value: 'update_other_user_avatar', enabled: true, checked: false, dependsOn: ['update_other_user_data', 'read_other_user_avatar', 'update_own_user_avatar'] },

		{ value: 'read_own_user_name', enabled: true, checked: false, dependsOn: ['read_own_user_data'] },
		{ value: 'read_other_user_name', enabled: true, checked: false, dependsOn: ['read_other_user_data', 'read_own_user_name'] },
		{ value: 'update_own_user_name', enabled: true, checked: false, dependsOn: ['update_own_user_data', 'read_own_user_name'] },
		{ value: 'update_other_user_name', enabled: true, checked: false, dependsOn: ['update_other_user_data', 'read_other_user_name', 'update_own_user_name'] },

		{ value: 'read_own_user_username', enabled: true, checked: false, dependsOn: ['read_own_user_data'] },
		{ value: 'read_other_user_username', enabled: true, checked: false, dependsOn: ['read_own_user_username', 'read_other_user_data'] },
		{ value: 'update_own_user_username', enabled: true, checked: false, dependsOn: ['update_own_user_data', 'read_own_user_username'] },
		{ value: 'update_other_user_username', enabled: true, checked: false, dependsOn: ['update_other_user_data', 'read_other_user_username', 'update_own_user_username'] },

		{ value: 'read_own_user_email', enabled: true, checked: false, dependsOn: ['read_own_user_data'] },
		{ value: 'read_other_user_email', enabled: true, checked: false, dependsOn: ['read_own_user_email', 'read_other_user_data'] },
		{ value: 'update_own_user_email', enabled: true, checked: false, dependsOn: ['update_own_user_data', 'read_own_user_email'] },
		{ value: 'update_other_user_email', enabled: true, checked: false, dependsOn: ['update_other_user_data', 'update_own_user_email', 'read_other_user_email'] },

		{ value: 'update_own_user_password', enabled: true, checked: false, dependsOn: ['update_own_user_data'] },
		{ value: 'update_other_user_password', enabled: true, checked: false, dependsOn: ['update_own_user_data', 'update_other_user_data', 'update_own_user_password', 'read_other_user_data'] },

		{ value: 'read_own_user_role', enabled: true, checked: false, dependsOn: ['read_own_user_data'] },
		{ value: 'read_other_user_role', enabled: true, checked: false, dependsOn: ['read_own_user_role', 'read_other_user_data'] },
		{ value: 'update_own_user_role', enabled: true, checked: false, dependsOn: ['update_own_user_data', 'read_own_user_role'] },
		{ value: 'update_other_user_role', enabled: true, checked: false, dependsOn: ['update_other_user_data', 'update_own_user_role', 'read_other_user_role'] },

		{ value: 'read_own_user_clients', enabled: true, checked: false, dependsOn: ['read_own_user_data'] },
		{ value: 'read_other_user_clients', enabled: true, checked: false, dependsOn: ['read_own_user_clients', 'read_other_user_data'] },
		{ value: 'update_own_user_clients', enabled: true, checked: false, dependsOn: ['update_own_user_data', 'read_own_user_clients'] },
		{ value: 'update_other_user_clients', enabled: true, checked: false, dependsOn: ['update_other_user_data', 'update_own_user_clients', 'read_other_user_clients'] },

		/**
		 * ROLE DATA PERMS
		 */
		{ value: 'create_global_role', enabled: true, checked: false, dependsOn: ['update_all_role_data'] },

		{ value: 'read_all_role_data', enabled: true, checked: false },
		{ value: 'update_all_role_data', enabled: true, checked: false, dependsOn: ['read_all_role_data'] },

		{ value: 'read_all_role_name', enabled: true, checked: false, dependsOn: ['read_all_role_data'] },
		{ value: 'update_all_role_name', enabled: true, checked: false, dependsOn: ['read_all_role_name', 'update_all_role_data'] },

		{ value: 'read_all_role_isGlobalRole', enabled: true, checked: false, dependsOn: ['read_all_role_data'] },
		{ value: 'update_all_role_isGlobalRole', enabled: true, checked: false, dependsOn: ['read_all_role_isGlobalRole', 'update_all_role_data'] },

		{ value: 'read_all_role_permissions', enabled: true, checked: false, dependsOn: ['read_all_role_data'] },
		{ value: 'update_all_role_permissions', enabled: true, checked: false, dependsOn: ['read_all_role_permissions', 'update_all_role_data'] },

		{ value: 'read_all_role_rank', enabled: true, checked: false, dependsOn: ['read_all_role_data'] },
		{ value: 'update_all_role_rank', enabled: true, checked: false, dependsOn: ['read_all_role_rank', 'update_all_role_data'] },

		{ value: 'delete_global_role', enabled: true, checked: false, dependsOn: ['update_all_role_data'] },

		/**
		 * CLIENT DATA PERMS
		 */
		{ value: 'create_global_client', enabled: true, checked: false, dependsOn: ['update_other_client_data', 'access_global_client_view'] },

		{ value: 'read_own_client_data', enabled: true, checked: false },
		{ value: 'update_own_client_data', enabled: true, checked: false, dependsOn: ['read_own_client_data'] },
		{ value: 'update_own_client_settings', enabled: true, checked: false, dependsOn: ['access_global_client_view'] },

		{ value: 'read_other_client_data', enabled: true, checked: false, dependsOn: ['read_own_client_data'] },
		{ value: 'update_other_client_data', enabled: true, checked: false, dependsOn: ['read_other_client_data', 'update_own_client_data'] },
		{ value: 'update_other_client_settings', enabled: true, checked: false, dependsOn: ['read_other_client_data'] },

		{ value: 'read_own_client_contentTree', enabled: true, checked: false, dependsOn: ['read_own_client_data'] },
		{ value: 'update_own_client_contentTree', enabled: true, checked: false, dependsOn: ['read_own_client_contentTree', 'update_own_client_data'] },

		{ value: 'read_other_client_contentTree', enabled: true, checked: false, dependsOn: ['read_own_client_contentTree', 'read_other_client_data'] },
		{ value: 'update_other_client_contentTree', enabled: true, checked: false, dependsOn: ['update_other_client_data', 'read_other_client_contentTree', 'update_own_client_contentTree'] },
		{ value: 'delete_global_client', enabled: true, checked: false, dependsOn: ['update_other_user_data'] },
		{ value: 'delete_global_client_permanently', enabled: true, checked: false, dependsOn: ['update_all_role_data'] },
	],
};
